import React from 'react';
import { Study } from '../../../domain/data/entries/Study';
import { unique } from '../../../viewComponents/results/utils/Unique';
import { IModal } from '../../../domain/interfaces/IModal';
import { translate } from '../../../infrastructure/translations/translate';
import {
	MultiChoiceDropdownDatasetResultReady
} from '../../../viewComponents/utils/MultiChoiceDropdownDatasetResultReady/MultiChoiceDropdownDatasetResultReady';
import { Alert } from '../../../viewComponents/Alerts';
import { useModal } from '../../../viewComponents/modal/useModal';
import { useDatasets } from '../../../viewComponents/hooks/useDatasets';

export const createDashboardModalEvolution = (
	open: boolean,
	setOpen: (value: boolean) => void,
	setEvolutionDatasets: (value: string[]) => void,
	studies: Study[] | undefined
): IModal | undefined => {
	const [selection, setSelection] = React.useState<boolean>(true);
	const [selectDatasetsIds, setSelectDatasetsIds] = React.useState<string[]>([]);
	const [functionalUnit, setFunctionalUnit] = React.useState<string>('');
	const { datasets } = useDatasets(selectDatasetsIds.map(d => d.includes('-') ? d.split('-').filter(t => t.length) : [d]).flat().filter(unique), true);

	if (!open) return;

	const clear = () => {
		setSelectDatasetsIds([]);
		setFunctionalUnit('');
		setSelection(true);
		useModal(false, { visible: false, body: undefined, header: '' });
	};
	const action = () => {
		if (selectDatasetsIds.length > 0) setSelection(false);
	};
	const actionAccept = () => {
		setEvolutionDatasets(selectDatasetsIds);
		clear();
		setOpen(false);
	};

	const functionalUnits = studies?.map(s => s.functionalUnit.name).filter(unique);

	const handleFunctionalUnitChange = (value: string) => {
		setFunctionalUnit(value);
		setSelectDatasetsIds([]);
	};

	if (selection) return {
		action,
		actionText: 'validate',
		body: <div className="dashboard_modal">
			<Alert variant={'info'}>
				{translate('dashboard.alert.usedIndicatorsAndLifeCycleSteps')}
				<br/><br/>
				{translate('dashboard.alert.functionalUnit')}
			</Alert>
			<select
				id={'functionalUnit'}
				className={'dashboard_modal__select_functional_unit'}
				onChange={(e) => handleFunctionalUnitChange(e.target.value)}
				value={functionalUnit ?? undefined}
				aria-label={translate('dashboard.modal.choose.functionalUnit') as string}
			>
				<option value={''}>{translate('dashboard.modal.choose.functionalUnit')}</option>
				{functionalUnits?.map((fu, index) => <option value={fu} key={index}>{fu}</option>)}
			</select>
			{functionalUnit.length > 0 && <div className="dashboard_modal__studies">
				<MultiChoiceDropdownDatasetResultReady
					selectDatasetsIds={selectDatasetsIds}
					setSelectDatasetsIds={setSelectDatasetsIds}
					functionalUnit={functionalUnit}
				/>
			</div>}
		</div>,
		header: translate('dashboard.modal.choose.a.study.and.a.dataset'),
		onClose(): void {
			clear();
			setOpen(false);
		},
		visible: true
	};
	else {
		const datasetList = selectDatasetsIds.map(id => {
			let dId = id;
			if (id.includes('-')) dId = id.split('-').filter(t => t.length)[0];
			const dataset = datasets?.find(d => d.id === dId);
			const study = studies?.find(s => s.id === dataset?.studyId);
			return {
				name: id.includes('-') ? translate('all') as string : dataset?.name,
				study: study?.name,
				company: study?.companyFk.name,
				year: study?.year,
			};
		}).filter(s => s.name && s.study);

		const list = datasetList.reduce<{
			name: string;
			study: string;
			company: string;
			years: number[];
		}[]>((acc, curr) => {
			const index = acc.findIndex(a => a.name === curr.name && a.study === curr.study && a.company === curr.company);
			if (index < 0) acc.push({
				name: curr.name ?? '',
				study: curr.study ?? '',
				company: curr.company ?? '',
				years: [curr.year ?? 0]
			});
			else {
				acc[index].years.push(curr.year ?? 0);
				acc[index].years = acc[index].years.filter(unique).sort((a, b) => a - b);
			}
			return acc;
		}, []);

		return {
			action: actionAccept,
			actionText: 'validate',
			body: <div className="dashboard_modal">
				<p>
					{translate('dashboard.evolution.modal.use')}
				</p>
				{list.every(l => l.years.length <= 1) ? <Alert variant={'warning'}>
					{translate('dashboard.evolution.modal.datasets.unique')}
				</Alert> : <p>
					{translate('dashboard.evolution.modal.datasets.multiple')}
				</p>}
				<table className={'dashboard_evolution_modal_table'}>
					<thead>
						<tr>
							<th>{translate('dashboard.evolution.modal.dataset')}</th>
							<th>{translate('dashboard.evolution.modal.study')}</th>
							<th>{translate('dashboard.evolution.modal.company')}</th>
							<th>{translate('dashboard.evolution.modal.years')}</th>
						</tr>
					</thead>
					<tbody>
						{list.map((l, index) => <tr key={index}>
							<td>{l.name}</td>
							<td>{l.study}</td>
							<td>{l.company}</td>
							<td>{l.years.join(', ')}</td>
						</tr>)}
					</tbody>
				</table>
				{list.every(l => l.years.length <= 1) ? <p>
					{translate('dashboard.evolution.modal.datasets.unique.support', {
						support: t => <a href={`mailto:${t}`}>{t}</a>
					})}
				</p> : <p>
					{translate('dashboard.evolution.modal.incorrect', {
						support: t => <a href={`mailto:${t}`}>{t}</a>
					})}
				</p>}
			</div>,
			header: translate('dashboard.modal.choose.a.study.and.a.dataset'),
			onClose(): void {
				clear();
				setOpen(false);
			},
			visible: true
		};
	}
};