import { Entity } from '../core/Entity';
import { IAddEquipmentCmdbValidation } from '../interfaces/IMutateAddEquipmentFromCmdb';
import { CmdbLine } from '../../views/InventoryView/ImportCMDB';
import { unique } from '../../viewComponents/results/utils/Unique';
import cloneDeep from 'lodash.clonedeep';
import { RdbDeviceType } from '../interfaces/TRdbDevice';
import {
	CmdbStep,
	CmdbStepState,
	CmdbSubStep,
	DefaultCmdbStepOrder,
	ValueSelectionSubStep,
	valueSelectionSubSteps
} from '../interfaces/TCmdbSteps';
import { CmdbChange } from '../interfaces/TCmdbChange';

export const cmdbInitialStep: CmdbStepState = { step: 'sheet', subStep: undefined, locked: false } as const;

export const cmdbMandatoryColumns = ['manufacturer', 'model', 'type', 'perimeter'];
export const cmdbNumericColumns = ['ramCount', 'ramSize', 'cpuCount', 'gpuCount', 'ssdCount', 'ssdSize', 'hddCount', 'screenSize', 'usage'];

export type CMDBType = RdbDeviceType | 'mix';
export type CMDBDeviceType = RdbDeviceType | 'rack_server' | 'blade_server' | 'tower_server';

export const cmdbTypes: CMDBType[] = ['server', 'laptop', 'switch', 'television', 'computer_monitor', 'storage_system', 'virtual_machine', 'desktop', 'smartphone', 'tablet', 'mix'];
export const cmdbDeviceTypes: CMDBDeviceType[] = ['server', 'rack_server', 'blade_server', 'tower_server', 'laptop', 'desktop', 'television', 'computer_monitor', 'smartphone', 'virtual_machine', 'tablet'];
export const ssdTechnologies= ['mlc', 'tlc', 'qlc', 'slc'];
export const screenTechnologies = ['lcd', 'oled'];

/**
 * This class is used to manage the CMDB information and carry all the CmdbAssistant logic
 * The CmdbAssistant is used to help the user to import data from a CMDB file (Excel or CSV)
 * The assistant is divided into 3 steps: sheet, column, value
 *  - sheet: select the sheet to import
 *  - column: divided in 3 subSteps: mandatory, optional, validation.
 *  - value: divided in multiple subSteps: info, type, ssdTechno, screenTechno, perimeter, country, numericalValue, finished.
 *
 *  The assistant sub-steps are used to match the columns/values of the CMDB file with our template
 */
export class CmdbEntity extends Entity {
	// Each index of data represent a step in CmdbAssistant
	public cmdbChanges: CmdbChange[] = [];
	public cmdbInfos: IAddEquipmentCmdbValidation = {
		blockId: '',
		cmdbName: undefined,
		sheet: [],
		startRow: 0,
		cmdbType: undefined,
		multipleDatasetMode: false,
	};

	private _initialCmdbLines: CmdbLine[] = [];
	private _currentStep: CmdbStepState = { step: 'sheet', subStep: undefined, locked: false };
	private _selectedSheetIndex = 0;
	private _stepOrder = { ...DefaultCmdbStepOrder };
	private _countryList: string[] = [];
	private _datasetNames: string[] = [];
	private _blocks: { id: string, name: string }[] = [];
	private _cmdbError: React.ReactNode = null;

	initialization() {
		this.app.adapter.storeCmdbData?.({ loading: false, data: this, error: null });
	}

	change(): Promise<void> | undefined {
		return this.app.adapter.storeCmdbData?.({ loading: false, error: null, data: this });
	}

	clear(): void {
		this.cmdbChanges = [];
		this.cmdbInfos = { blockId: '', cmdbName: undefined, sheet: [], startRow: 0, cmdbType: undefined };
		this._currentStep = { step: 'sheet', subStep: undefined, locked: false };
		this._selectedSheetIndex = 0;
		this._stepOrder = { ...DefaultCmdbStepOrder };
		this._datasetNames = [];
		this._initialCmdbLines = [];
		this.change();
	}

	/**
	 * This function is used if the user wants to previous step from the 'value' step to the 'column' step
	 * We have to restore the initial state of the cmdb lines because columns changes have been applied
	 * So some columns may have been removed from the cmdb lines, and some have been renamed
	 *
	 * @private
	 */
	private _restoreInitialColumnState(): void {
		this.cmdbChanges = [];
		this.cmdbInfos.sheet[this._selectedSheetIndex].cmdb = cloneDeep(this._initialCmdbLines);
		this._stepOrder = { ...DefaultCmdbStepOrder };
		this.setCmdbCurrentStep({ step: 'column', subStep: 'mandatory', locked: true });
		this.change();
	}

	/**
	 * This function is called when the user selects a new sheet in the CMDB assistant
	 * This allows to save the initial state of Cmdb lines in case of returning to first step
	 *
	 * @private
	 */
	// TODO cmdb: change when save function is called because right now it does not work with multiple sheets
	private _saveInitialCmdbLines(): void {
		if (this._selectedSheetIndex >= this.cmdbInfos.sheet.length) {
			this.setCmdbError('inventory.addEquipment.modal.cmdb.error.sheetIndexOutOfRange');
			return;
		}
		// We are using cloneDeep to avoid mutation of the original data
		// Spread does not work for nested objects
		this._initialCmdbLines = cloneDeep(this.cmdbInfos.sheet[this._selectedSheetIndex].cmdb);
		this.change();
	}

	/**
	 * Value sub-steps are dynamically set based on the columns of the CMDB
	 * Because we want the assistant to be straight to the points, some sub-steps can be skipped
	 * This setter is called after applying column changes, allowing us to determine which sub-steps are relevant
	 *
	 * @private
	 */
	private _setValuesSubSteps(): void {
		const lines = [ ...this.getCmdbLines() ];
		if (!lines.length) return;

		// WARNING: numericalValue subStep carry all numeric change that will happen in cmdb
		// Here, we filter out non-concerned subSteps and numericalValue because we want to check all column of the cmdb (including all numerical columns)
		const subSteps: CmdbSubStep<'value'>[] = { ...DefaultCmdbStepOrder }.value.filter(s => s !== 'finished' && s !== 'info' && s !== 'numericalValue');
		// Remove 'block' subStep if the cmdb type is not 'mix'
		if (this.cmdbInfos.cmdbType !== 'mix' || this.cmdbInfos.blockId) subSteps.splice(subSteps.indexOf('block'), 1);
		// Push all numerical columns in the subSteps
		subSteps.push(...cmdbNumericColumns);

		// Get all line keys
		const keys = Object.keys(lines[0]);

		// Iterate over filtered subSteps, removing each columns (subSteps) that are not in cmdb lines to avoid looping a column (subStep) that does not exist
		const valueSubSteps = subSteps.filter(subStep => keys.includes(subStep)).reduce<string[]>((acc, subStep) => {
			if (subStep === 'block' && this.cmdbInfos.cmdbType === 'mix' && !this.cmdbInfos.blockId) {
				acc.push(subStep);
				return acc;
			}
			const uniqueValues = lines
				.map(line => line[subStep] ?? '')
				.filter(unique)
				.filter(item => item !== '');

			// Check if the subStep is a numerical column
			if (cmdbNumericColumns.includes(subStep)) {
				const isWrongValues = uniqueValues
					.filter(item => isNaN(Number(item)))
					.length > 0;
				// As the numericalValue step carry all numeric changes
				// We need to add this step one time if there is a wrong value in a numerical column
				// All changes will be detected in component later
				if (isWrongValues && !acc.includes('numericalValue')) acc.push('numericalValue');
				return acc;
			}

			// Else the column can match only with the target list
			const targetList = this.getTargetList(subStep);
			const isWrongValues = uniqueValues
				.filter(item => !targetList.includes(item.toLowerCase()))
				.length > 0;
			if (isWrongValues) acc.push(subStep);
			return acc;
		}, []);

		// Ensure 'info' is at the start and 'finished' are at the end
		const newValue = [
			'info',
			...valueSubSteps,
			'finished'
		] as const;

		// Use type assertion to assign the new value
		this._stepOrder = {
			...this._stepOrder,
			value: newValue as typeof this._stepOrder.value
		};

		this.change();
	}

	private _insertBlockColumn(): void {
		const lines = this.getCmdbLines();
		if (!lines.length) return;
		// Check if there is a blockId in infos, and if each line got a block key
		const isLineWithoutBlock = this.getCmdbLines().some((line) => !line.block);
		if (isLineWithoutBlock) {
			this.setCmdbLines(this.getCmdbLines().map((line) => {
				if (!this.cmdbInfos.blockId) return line;
				line['block'] = this.cmdbInfos.blockId ;
				return line;
			}));
		}
	}
	/************************************************************************************************************
	 * 													Setters													*
	 * **********************************************************************************************************/

	set(obj: CmdbChange[] | undefined): void {
		if (!obj) obj = [];
		this.cmdbChanges = obj;
		this.change();
	}

	setCmdbLines(lines: CmdbLine[]): void {
		this.cmdbInfos.sheet[this._selectedSheetIndex].cmdb = lines;
		this.change();
	}

	setCmdbData(data: IAddEquipmentCmdbValidation, datasets?: string[]): void {
		this.cmdbInfos = data;
		// We set dataset names dynamically based on the datasets available in the study
		if (datasets && datasets.length > 0) this.setDatasetNames(datasets);
		if (this._countryList.length === 0) this.app.entities.equipments.queryCountriesOfUse().then((countries) => countries.map((c) => this._countryList.push(c.name.toLowerCase())));
		if (this._blocks.length === 0 && this.cmdbInfos.cmdbType === 'mix' && !this.cmdbInfos.blockId) this.app.entities.blocks.get()?.map((b) => this._blocks.push({ id: b.id, name: b.name }));
		this.change();
	}

	setCmdbError(error: React.ReactNode): void {
		this._cmdbError = error;
		this.change();
	}

	setDatasetNames(datasets: string[]): void {
		this._datasetNames = datasets;
		this.change();
	}

	setSelectedSheetIndex(index: number): void {
		this._selectedSheetIndex = index;
		this._insertBlockColumn();
		this.change();
	}

	setStepLockedState(value: boolean): void {
		if (value === this._currentStep.locked) return;
		this._currentStep.locked = value;
		this.change();
	}

	/**
	 * Change the current step of the CmdbAssistant
	 * Also, add a new object in data representing the new step
	 *
	 * @param newStep {CmdbStepState} The new step to set
	 */
	setCmdbCurrentStep(newStep: CmdbStepState): void {
		if (newStep.step === this._currentStep.step && newStep.subStep === this._currentStep.subStep) return;
		this._currentStep = newStep;
		if (!this.cmdbChanges.find((s) => s.stepState.step === newStep.step && s.stepState.subStep === newStep.subStep)) {
			this.cmdbChanges.push({ valueChanges: [], columnChanges: [], stepState: { step: this._currentStep.step, subStep: this._currentStep.subStep, locked: false } });
		}
		this.change();
	}

	/************************************************************************************************************
	 * 													Getters													*
	 * **********************************************************************************************************/

	/**
	 * WARNING: Getters returning spread arrays or objects are made to avoid mutation of the original data
	 */

	getInitialCmdbLines(): CmdbLine[] {
		return this._initialCmdbLines.map(line => ({ ...line }));
	}

	getCmdbError(): React.ReactNode {
		return this._cmdbError;
	}

	getCmdbLines(): CmdbLine[] {
		if (!this.cmdbInfos.sheet.length) return [];
		if (this._selectedSheetIndex >= this.cmdbInfos.sheet.length) return [];
		return this.cmdbInfos.sheet[this._selectedSheetIndex].cmdb.map(line => ({ ...line }));
	}

	getCmdbCurrentStep(): CmdbStepState {
		return { ...this._currentStep };
	}

	getLastStepState(): CmdbStepState {
		if (!this.cmdbChanges.length) return cmdbInitialStep;
		const lastChange = this.cmdbChanges[this.cmdbChanges.length - 1];
		return { ...lastChange.stepState };
	}

	getSelectedSheetIndex(): number {
		return this._selectedSheetIndex;
	}

	getCurrentStepLockedState(): boolean {
		return this._currentStep.locked;
	}

	getStepOrder(): typeof DefaultCmdbStepOrder {
		return {
			sheet: [...this._stepOrder.sheet],
			column: [...this._stepOrder.column],
			value: [...this._stepOrder.value]
		};
	}

	getDatasetNames(): string[] {
		return [ ...this._datasetNames ];
	}

	/**
	 * For a given CmdbCurrentStep, find a change stored in data, and return the corresponding value
	 * If no changes found, return the initial value
	 *
	 * @param subStep {CmdbSubStep<'column'>} The subStep to find in data
	 * @param initialColumn {string} The initialColumn (corresponding to a valid column value) to find in the column changes
	 */
	getNewColumnName(subStep: CmdbSubStep<'column'>, initialColumn: string): string {
		const cmdbChange = this.cmdbChanges.find((s) => s.stepState.subStep === subStep);
		if (!cmdbChange) return initialColumn;
		const change = cmdbChange.columnChanges.find((change) => change.initialName === initialColumn);
		return change?.newName ?? initialColumn;
	}

	/**
	 * @returns {CmdbChange | undefined} The changes corresponding to the current step, or undefined if no changes found
	 *
	*/
	getCurrentStepChanges(): CmdbChange | undefined{
		const currentStep = this._currentStep;
		return this.cmdbChanges.find((change) =>
			change.stepState.step === currentStep.step && change.stepState.subStep === currentStep.subStep
		);
	}

	/**
	 * Get the changes for a given step and subStep
	 * @param step {CmdbStep} The step to find in data
	 * @param subStep {CmdbSubStep<CmdbStep>} The subStep to find in data
	 */
	getStepChanges(step: CmdbStep, subStep: CmdbSubStep<CmdbStep>): CmdbChange | undefined {
		return this.cmdbChanges.find((change) => change.stepState.step === step && change.stepState.subStep === subStep);
	}

	/************************************************************************************************************
	 * 													Step Tools												*
	 * **********************************************************************************************************/

	startAssistant(): void {
		this._insertBlockColumn();
		// When there is only one sheet, we skip the sheet step
		if (this._currentStep.step === 'sheet' && this.cmdbInfos.sheet.length === 1) {
			this._saveInitialCmdbLines();
			this.setCmdbCurrentStep({ step: 'column', subStep: 'mandatory', locked: true });
		} else {
			this.setCmdbCurrentStep({ ...cmdbInitialStep });
		}
	}

	nextStep(callback?: () => void): void {
		if (this.getCurrentStepLockedState()) return;
		const steps = Object.keys(DefaultCmdbStepOrder) as CmdbStep[];
		if (this._currentStep.step === 'sheet') this._saveInitialCmdbLines();

		for (let i = 0; i < steps.length; i++) {
			const step = steps[i];
			const subSteps = this._stepOrder[step];

			for (let j = 0; j < subSteps.length; j++) {
				const subStep = subSteps[j];

				if (step === this._currentStep.step && subStep === this._currentStep.subStep) {
					const isSubStepLast = j === subSteps.length - 1;
					const isStepLast = step === 'value' && isSubStepLast;

					if (isStepLast && callback) {
						callback();
						return;
					} else if (isSubStepLast) {
						// Move to the next step
						const nextStep = steps[i + 1];
						this._currentStep = { step: nextStep, subStep: DefaultCmdbStepOrder[nextStep][0], locked: false };
						this.cmdbChanges.push({ valueChanges: [], columnChanges: [], stepState: this._currentStep });

						// Apply column changes before moving to the value step
						if (step === 'column') {
							this.applyColumnChangesToCmdbLines();
							return;
						} 
						this.change();
					} else {
						// Move to the next subStep
						this.setCmdbCurrentStep({ step, subStep: subSteps[j + 1], locked: false });
					}
					return;
				}
			}
		}
	}

	previousStep(setCmdbMode?: (mode: 'import' | 'assistant') => void): void {
		const steps = Object.keys(DefaultCmdbStepOrder) as CmdbStep[];

		// If there is only one sheet, we skip the sheet step and go back to import mode
		if (this._currentStep.step === 'column' && this._currentStep.subStep === 'mandatory' && this.cmdbInfos.sheet.length === 1) {
			this.clear();
			setCmdbMode && setCmdbMode('import');
			return;
		}

		for (let i = steps.length - 1; i > -1; i--) {
			const step = steps[i];
			const subSteps = this._stepOrder[step];
			if (step === 'sheet') {
				this.clear();
				return;
			}

			for (let j = subSteps.length - 1; j > -1; j--) {
				const subStep = subSteps[j];

				if (step === this._currentStep.step && subStep === this._currentStep.subStep) {
					const isSubStepFirst = j === 0;

					if (isSubStepFirst) {
						// If we are at first step of value - previous will be column - we need to restore the initial lines
						if (step === 'value') {
							this._restoreInitialColumnState();
							return;
						}

						// Move to the previous step and last subStep
						const previousStep = steps[i - 1];
						const previousSubSteps = DefaultCmdbStepOrder[previousStep];
						this._currentStep = { step: previousStep, subStep: previousSubSteps[previousSubSteps.length - 1], locked: false };
						this.change();
					} else {
						// Move to the previous subStep
						this.setCmdbCurrentStep({ step, subStep: subSteps[j - 1], locked: false });
					}
					return;
				}
			}
		}
	}

	isInfoValueStep(): boolean {
		return this._currentStep.step === 'value' && this._currentStep.subStep === 'info';
	}

	isSelectionValueStep(): boolean {
		return this._currentStep.step === 'value' && valueSelectionSubSteps.includes(this._currentStep.subStep as string);
	}

	isNumericalValueStep(): boolean {
		return this._currentStep.step === 'value' && this._currentStep.subStep === 'numericalValue';
	}

	isFinishedValueStep(): boolean {
		return this._currentStep.step === 'value' && this._currentStep.subStep === 'finished';
	}

	/************************************************************************************************************
	 * 													Functions												*
	 * **********************************************************************************************************/

	/**
	 * Add a column change to the current step (last index) of the data
	 *
	 * @param initialValue {string} The value that will change
	 * @param drop {boolean} If the column will be dropped
	 * @param newValue {string} The value that will replace the value
	 */
	addColumnChangeToCurrentStep(initialValue: string, drop: boolean, newValue?: string): void {
		// If there is no change between the initial value and the new value
		// And there is nothing to drop -> we should do nothing
		if (newValue && initialValue === newValue && !drop) return;

		if (!this.cmdbChanges.length) {
			this.cmdbChanges.push({
				valueChanges: [],
				columnChanges: [],
				stepState: { step: this._currentStep.step, subStep: this._currentStep.subStep, locked: this._currentStep.locked }
			});
		}
		const currentStepIndex = this.cmdbChanges.findIndex((change) => change.stepState.step === this._currentStep.step && change.stepState.subStep === this._currentStep.subStep);

		// Do not add multiple change for the same value in the same step
		const existingChange = this.cmdbChanges[currentStepIndex].columnChanges.find((change) => change.initialName === initialValue);
		if (existingChange) {
			if (newValue) existingChange.newName = newValue;
			existingChange.drop = drop;
			this.change();
			return;
		}

		this.cmdbChanges[currentStepIndex].columnChanges.push({
			initialName: initialValue,
			newName: newValue,
			drop
		});
		this.change();
	}

	/**
	 * Add a value change to the current step (last index) of the data
	 *
	 * @param initialValue {string} The value that will change
	 * @param column {string} The column of the value
	 * @param drop {boolean} If the value will be dropped
	 * @param newValue {string} The value that will replace the value
	 */
	addValueChangeToCurrentStep(initialValue: string, column: string, drop: boolean, newValue?: string): void {
		// If there is no change between the initial value and the new value
		// And there is nothing to drop -> we should do nothing
		if (newValue && initialValue === newValue && !drop) return;
		if (!this.cmdbChanges.length) {
			this.cmdbChanges.push({
				valueChanges: [],
				columnChanges: [],
				stepState: {
					step: this._currentStep.step,
					subStep: this._currentStep.subStep,
					locked: this._currentStep.locked
				}
			});
		}
		const currentStepIndex = this.cmdbChanges.findIndex((change) => change.stepState.step === this._currentStep.step && change.stepState.subStep === this._currentStep.subStep);

		// Do not add multiple change for the same value in the same step
		const existingChange = this.cmdbChanges[currentStepIndex].valueChanges.find((change) => change.initialName === initialValue);
		if (existingChange) {
			if (newValue) existingChange.newName = newValue;
			existingChange.column = column;
			existingChange.drop = drop;
			this.change();
			return;
		}

		this.cmdbChanges[currentStepIndex].valueChanges.push({
			initialName: initialValue,
			newName: newValue,
			column,
			drop
		});
		this.change();
	}

	/**
	 * Set drop to true for a column change in a given step
	 * Available only for the column step, optional subStep
	 *
	 * @param initialValue {string} The column that will be removed from the data
	 */
	dropColumnChange(initialValue: string): void {
		if (!this.cmdbChanges.length) return;
		if (this._currentStep.step !== 'column' || this._currentStep.subStep !== 'optional') return;
		const columnChanges = this.cmdbChanges.find((change) => change.stepState.step === this._currentStep.step && change.stepState.subStep === this._currentStep.subStep)?.columnChanges;
		const columnChange = columnChanges?.find((change) => change.initialName === initialValue);
		if (!columnChange) return;
		columnChange.drop = true;
		columnChange.newName = undefined;
		this.change();
	}

	/**
	 * Remove a column change from the current ste
	 * Only available for the column step - mandatory subStep
	 *
	 * @param initialValue {string} The column that will be removed from the data
	 */
	removeColumnChangeFromCurrentStep(initialValue: string): void {
		if (!this.cmdbChanges.length) return;
		if (this._currentStep.step !== 'column' || this._currentStep.subStep !== 'mandatory') return;
		const columnChanges = this.cmdbChanges.find((change) => change.stepState.step === this._currentStep.step && change.stepState.subStep === this._currentStep.subStep)?.columnChanges;
		if (!columnChanges) return;
		const columnChangeIndex = columnChanges.findIndex((change) => change.initialName === initialValue);
		if (columnChangeIndex === -1) return;
		columnChanges.splice(columnChangeIndex, 1);
		this.change();
	}

	/**
	 * Remove a value change from the current step (last index) of the data
	 *
	 * @param initialValue {string} The value that will be removed from the data
	 */
	removeValueChangeFromCurrentStep(initialValue: string): void {
		if (!this.cmdbChanges.length) return;
		const currentStepIndex = this.cmdbChanges.findIndex((change) => change.stepState.step === this._currentStep.step && change.stepState.subStep === this._currentStep.subStep);
		const valueChanges = this.cmdbChanges[currentStepIndex].valueChanges;
		const valueChangeIndex = valueChanges.findIndex((change) => change.initialName === initialValue);
		if (valueChangeIndex === -1) return;
		valueChanges.splice(valueChangeIndex, 1);
		this.change();
	}

	/**
	 * Apply all values changes to the CMDB lines
	 **/
	applyValueChangesToCmdbLines() {
		if (!this.cmdbInfos.sheet.length) return;
		if (this._selectedSheetIndex >= this.cmdbInfos.sheet.length) return;
		let lines = this.cmdbInfos.sheet[this._selectedSheetIndex].cmdb;

		const valueChanges = this.cmdbChanges.filter((change) => change.stepState.step === 'value').map((c) => c.valueChanges).flat();
		valueChanges.forEach((change) => {
			if (change.drop) {
				// Remove each lines that have to be dropped (we keep only lines that are not equal to the initial value)
				lines = lines.filter(line => line[change.column] !== change.initialName);
			} else {
				// Update each line with the new value
				lines = lines.map(line => {
					if (line[change.column] === change.initialName) {
						// update the value
						return { ...line, [change.column]: change.newName };
					}
					return line;
				});
			}
		});
	
		this.cmdbInfos.sheet[this._selectedSheetIndex].cmdb = lines;
		this.change();
	}

	/**
	 * Apply all column changes to the CMDB lines
	 **/
	applyColumnChangesToCmdbLines() {
		if (!this.cmdbInfos.sheet.length) return;
		if (this._selectedSheetIndex >= this.cmdbInfos.sheet.length) return;
		const lines = this.cmdbInfos.sheet[this._selectedSheetIndex].cmdb;
		const columnChanges = this.cmdbChanges.filter((change) => change.stepState.step === 'column').map((c) => c.columnChanges).flat();
		columnChanges.forEach((change) => {
			lines.forEach((line) => {
				if (change.drop) {
					delete line[change.initialName];
				} else {
					if (change.newName) {
						line[change.newName] = line[change.initialName];
						delete line[change.initialName];
					}
				}
			});
		});
		// If the lines do not have a 'block' column, and we are in mix mode without a block selected, we add the block column
		if (lines.length > 0 && !lines[0].block && this.cmdbInfos.cmdbType === 'mix' && !this.cmdbInfos.blockId) {
			lines.forEach((line) => line['block'] = '');
		}
		// Set the new values subSteps that will be displayed by the assistant
		this._setValuesSubSteps();
	}

	/**
	 * Insert a key type for each CMDB line corresponding to the Cmdb type
	 * It's used when a user has selected a type in the CmdbAssistant which is not 'mix'
	 *
	 * @param type {string} The type to insert in the CMDB lines
	 */
	insertColumnTypeInCmdb(type: string): void {
		const serverTypeAccepted = ['rack_server', 'blade_server', 'tower_server'];
		this.cmdbInfos.sheet[this._selectedSheetIndex].cmdb.forEach((line) => {
			if (serverTypeAccepted.includes(line.type ?? '') && type === 'server') 
				return;
			line['type'] = type;
		});
		this.change();
	}

	/**
	 * This function is used for value sub-steps where only some values are accepted
	 * It returns the list of values that are accepted for a given sub-step
	 *
	 * @param key {ValueSelectionSubStep} - The key corresponding to a value sub-step
	 */
	getTargetList(key: ValueSelectionSubStep): string[] {
		switch (key) {
			case 'type':
				return cmdbDeviceTypes;
			case 'ssdTechno':
				return ssdTechnologies;
			case 'screenTechno':
				return screenTechnologies;
			case 'perimeter':
				return this._datasetNames;
			case 'country':
				return this._countryList;
			case 'block':
				return this._blocks.map((b) => b.name);
			default:
				return [];
		}
	}

	update(): void {
		return;
	}
}

